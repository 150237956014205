import React, { useEffect, useState } from "react"
import { TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import apiService, { getDefaultValues } from "../../services/api";
import FormService from "../../services/form";

const selectIcon = (icon, startAdornment) => {
  const data = [];

  if (!icon) {
    return startAdornment;
  }

  data.push(icon);

  if (!startAdornment) {
    return data;
  }

  return [...data, ...startAdornment];
};

export default function AppAutocomplete({
  setValue,
  data,
  name,
  apiSearchName,
  label,
  errorsForm,
  errorsApi,
  api,
  required,
  multiple,
  fieldId = 'id',
  inputIcon,
  onChange,
  TextFieldProps,
  field,
  optionsDefault,
  defaultValueId,
  disabled,
  disableClearable,
}) {
  const [options, setOptions] = React.useState(optionsDefault);
  const [loading, setLoading] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState(undefined);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [loaded, setLoaded] = React.useState(undefined);
  const [loadedFirstTime, setLoadedFirstTime] = React.useState(undefined);
  const [searchTermBefore, setSearchTermBefore] = React.useState(undefined);

  const apiJoin = api && api.search("\\?") === -1 ? '?' : '&';

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        if (searchTerm === 0) return;
        if (!loadedFirstTime && optionsDefault) {
          if (optionsDefault) {
            if (multiple) {
              setDefaultValue(defaultValueId);
            } else {
              setDefaultValue(optionsDefault.find(d => d[fieldId] === defaultValueId));
            }
          }
          return setLoaded(true);
        }

        setLoading(true);

        let response;

        if (
          !loaded &&
          !loadedFirstTime &&
          data &&
          data[name] &&
          (typeof data[name] === 'string' || (Array.isArray(data[name]) && data[name].length > 0)) &&
          !options) {
          if (!optionsDefault) {
            response = await getDefaultValues(api, data, name, multiple, fieldId);

            if (multiple) {
              const newValue = response?.data?.data;
              setDefaultValue(newValue);
              setValue(name, newValue?.map(row => row[fieldId]));
            } else {
              const newValue = response?.data?.data.find(row => row[fieldId] === data[name]);

              setDefaultValue(newValue);
              setValue(name, newValue && newValue[fieldId]);
              setValue(`obj_${name}`, newValue && newValue[fieldId]);
            }

            const responseData = response?.data?.data;
            setOptions(responseData ? responseData : []);
          }
        } else {
          // if (searchTermBefore === searchTerm) return;

          if (api) {
            const strSearchTerm = !searchTerm ? '' : searchTerm;
            response = await apiService.get(`${api}${apiJoin}${apiSearchName}=${strSearchTerm}&_op_name=contains`);
            const responseData = response?.data?.data;

            if (defaultValueId && responseData) {
              setDefaultValue(responseData.find(d => d[fieldId] === defaultValueId));
            }

            setOptions(responseData ? responseData : []);
          }
        }

        setLoading(false);
        setSearchTermBefore(searchTerm);
        setLoaded(true);
      })();
    }, 500)

    setLoadedFirstTime(true);

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm]);

  return (
    <>
      {loaded || (options && !defaultValueId) ? (
        <>
          <Autocomplete {...field}
            multiple={multiple}
            options={options}
            disabled={disabled}
            disableClearable={disableClearable}
            defaultValue={optionsDefault ? (defaultValueId ? defaultValue : undefined) : defaultValue}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option[fieldId] === value[fieldId];
            }}
            onChange={(e, option) => {
              let value;

              if (multiple) {
                value = [];
                option.forEach(row => {
                  value.push(row[fieldId]);
                });
              } else {
                value = option && option[fieldId];
              }

              if (onChange) {
                onChange(e, option);
              }

              setValue(`obj_${name}`, option);
              setValue(name, value);
            }}
            loading={loading}
            loadingText="Carregando..."
            noOptionsText="Sem opções"
            openText="Abrir"
            closeText="Fechar"
            clearText="Limpar"
            onInputChange={(e) => {
              if (!e) return;

              if (e.target.value === undefined) {
                setSearchTerm('');
              } else {
                setSearchTerm(e.target.value);
              }
            }}

            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                {...TextFieldProps}
                label={
                  <>
                    {label}{required && '*'}
                  </>
                }
                error={FormService.hasError(name, errorsForm, errorsApi)}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectIcon(inputIcon, params.InputProps.startAdornment),
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </>
      ) : <CircularProgress margin="normal" color="inherit" size={20} />}
    </>
  );
}
